<script setup lang="ts">
import { IWalletBase, useWalletStore } from "~/services/WalletManager/store";
import { toast } from "../ui/toast";
const wallets = useWalletStore();
const props = defineProps<{
  wallet: IWalletBase;
  index: number;
  onSelected: (address: string) => void;
}>();
const setting = useSettingStore();
const totalBalance = ref(0);
const loading = ref(false);
const tokens = useTokens();

async function getTotalUSDBalance() {
  const walletAddress = props.wallet.smart_address;
  const listWalletBalanceSaved = localStorage.getItem("listWalletBalance") || "{}";
  const listWalletBalance = JSON.parse(listWalletBalanceSaved);
  const walletBalance = listWalletBalance[walletAddress] || 0;
  totalBalance.value = walletBalance;
  loading.value = true;
  let newBalance = 0;
  let count = 0;
  tokens.tokens.forEach(async (item) => {
    const balance = await getBalanceOfToken({
      contractAddress: item.contract_address,
      userAddress: walletAddress,
      chainID: item.chain_id,
      decimal: item.decimal,
      standard: item.contract_standard,
    });
    newBalance += Number(balance) * Number(item.price_usd);
    count += 1;
    if (count === tokens.tokens.length) {
      loading.value = false;
      totalBalance.value = newBalance;
      localStorage.setItem("listWalletBalance", JSON.stringify({ ...listWalletBalance, [walletAddress]: newBalance }));
    }
  });
}

onMounted(() => {
  getTotalUSDBalance();
});

function onSelectWallet(wallet: any) {
  props.onSelected(wallet.smart_address);
  if (wallet.smart_address === wallets.getCurrentWallet()?.smart_address) return;
  wallets.setCurrentAddress(wallet.smart_address);
  toast({
    title: "Switch wallet success",
    duration: 3000,
  });
}
</script>

<template>
  <div
    class="p-3 row-center cursor-pointer"
    :class="{
      ' border-t-[1px] border-t-app-line3 ': index > 0,
      'bg-[#155220]': wallet.smart_address === wallets.getCurrentWallet()?.smart_address,
    }"
    @click="onSelectWallet(wallet)"
  >
    <img src="/images/icon-logo-evm.svg" class="w-[28px]" />
    <div class="ml-2 flex-1">
      <p class="text-[16px] font-[600]">{{ wallet.name }}</p>
      <p class="mt-1 text-[#B0B0B0]">{{ shortAddress(wallet.smart_address) }} - ${{ totalBalance.toFixed(2) }}</p>
    </div>
    <div class="row-center ml-2">
      <div v-if="wallet.is_smart" class="text-[10px] py-[2px] px-1 bg-[#6D6D6D] rounded-[2px] mr-2">
        <span>SMART</span>
      </div>
      <div class="w-[16px]">
        <img v-if="wallet.smart_address === wallets.getCurrentWallet()?.smart_address" src="/images/icon-checked-round.svg" />
      </div>
    </div>
  </div>
</template>
