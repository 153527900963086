import { toast } from "~/components/ui/toast";
import Fetch from "..";
import errorHandler from "../ErrorHandler";
import {
  ICreateTransactionBody,
  ICreateTxResponse,
  IHistoryDetailResponse,
  IInitTransactionBody,
  ISendTransaction,
  ITransactionHistoryResponse,
} from "./type";

export async function initTransfer(body: ICreateTransactionBody): Promise<ICreateTxResponse | null> {
  try {
    const { data } = await Fetch.post<{ data: any }>(`@api/smart-wallets/assets/transfer/init`, body);
    return data.data;
  } catch (error) {
    console.log("initTransfer er", error);
    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: msg,
      duration: 3000,
    });
    return null;
  }
}

export async function sendTransaction(body: ISendTransaction) {
  try {
    const { data } = await Fetch.post<{
      data: {
        tx_hash: string;
      };
    }>(`@api/smart-wallets/transactions/send`, body);
    return data.data;
  } catch (error) {
    console.log("sendTransaction er", error);

    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: msg,
      duration: 3000,
    });
    return null;
  }
}

export async function sendConfirmSession(body: ISendTransaction) {
  try {
    const { data } = await Fetch.post<{
      data: {
        tx_hash: string;
      };
    }>(`@api/smart-wallets/sessions/confirm`, body);
    return data.data;
  } catch (error) {
    console.log("sendConfirmSession er", error);
    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: msg,
      duration: 3000,
    });
    return null;
  }
}

export async function getTransactionHistory(page: number, params?: { address?: string; chain_id?: number }) {
  try {
    const { data } = await Fetch.get<{ data: ITransactionHistoryResponse }>(`@api/transactions/history`, {
      params: {
        limit: 100,
        page: page,
        ...params,
      },
    });
    return data.data;
  } catch (error) {
    console.log("getTransactionHistory er", error);
    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: msg,
      duration: 3000,
    });
    return null;
  }
}

export async function getTransactionHistoryDetail(txhash: string, chain_id: number) {
  try {
    const { data } = await Fetch.get<{ data: IHistoryDetailResponse }>(`@api/transactions/${txhash}`, {
      params: {
        chain_id,
      },
    });
    return data.data;
  } catch (error) {
    console.log("getTransactionHistoryDetail er", error);
    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: msg,
      duration: 3000,
    });
    return null;
  }
}

export async function initTransaction(body: IInitTransactionBody): Promise<ICreateTxResponse | null> {
  try {
    const { data } = await Fetch.post<{ data: any }>(`@api/smart-wallets/transactions/init`, body);
    return data.data;
  } catch (error) {
    console.log("initTransaction er", error);
    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: msg,
      duration: 3000,
    });
    return null;
  }
}
