import { defineStore } from "pinia";
import { useMPCWallet } from "./mpc_wallet";
import { useNormalWallet } from "./normal_wallet";
import { WALLET_TYPE } from "./type";
import { BLOCKCHAIN_TYPE, ISmartWallet } from "../api/auth/type";
import { isEmpty } from "lodash";
import PasswordService from "../security/PasswordService";
import Crypto from "../encrypt/EncryptionService";
import { useTraditionalWallet } from "./traditional_wallet";
import { createImportWallet } from "./import_wallet";
import { postImportWallet } from "../api/wallet/api";
import { Hex } from "viem";
import { getUserInfo } from "../api/auth/api";

export interface IWalletBase {
  address: any;
  smart_address: any;
  is_smart: boolean;
  smart_data: ISmartWallet | undefined;
  signMessage: (message: string, option?: { hash?: boolean }) => Promise<`0x${string}`>;
  generateWallet: (pwd: string, hint: string) => Promise<any>;
  saveWalletToCloud?: (callbackProgress?: (progress: number) => void) => Promise<boolean>;
  tgCloudBackuped?: boolean;
  signTypedData: (payload: any) => Promise<`0x${string}`>;
  wallet_type: WALLET_TYPE;
  blockchain: BLOCKCHAIN_TYPE;
  name: string;
  smart_name: string;
  secretKey?: string;
  clear: (id: string) => void;
  getSavedWallet: () => Promise<void>;
}

export const useWalletStore = (storeKey?: string) =>
  defineStore("wallet_store", () => {
    const wallets = ref<IWalletBase[]>([]);
    const currentSelectAddress = useLocalStorage("current_select_address", "");
    const { getUser, setUser } = useAuthStore();
    const normalWallet = useNormalWallet();
    const tmpSecret = ref<any>({});
    const storePwd = storeKey || getUser().id;

    const importDone = ref(false);

    if (!storePwd) {
      // Should never have reached
      alert("Error when init wallet store");
      throw new Error("Error when init wallet store, missing store pwd");
    }
    const mpcWallet = useMPCWallet(storePwd);
    const traditionalWallet = useTraditionalWallet(storePwd);
    const initDone = ref(false);
    const pwdSaved = ref("");

    async function init() {
      console.log("init wallets");

      if (wallets.value.length > 0 && pwdSaved.value === PasswordService.getPassword()) return;

      pwdSaved.value = PasswordService.getPassword();
      wallets.value = [];
      // create MPC wallet
      await mpcWallet.getSavedWallet();
      await mpcWallet.checkSavedToTgCloud();
      wallets.value.push(mpcWallet);
      wallets.value.push(traditionalWallet);

      try {
        normalWallet.getSavedWallet();
        if (normalWallet.address) wallets.value.push(normalWallet);
      } catch (error) {
        console.log("normal wallet error", error);
      }
      initDone.value = true;
    }

    async function initImportWallet() {
      if (importDone.value) return;
      const listSaved = localStorage.getItem("import_wallets");
      if (listSaved) {
        const list = JSON.parse(listSaved);
        if (list && list.length > 0) {
          for (const item of list) {
            const uw = getUser().user_wallets.find((uw) => uw.address === item.address);
            if (!uw) continue;
            const pvk = Crypto.decrypt(item.encPvk, PasswordService.getPassword()) as Hex;
            if (!pvk) continue;
            const w = createImportWallet({
              pvk: pvk,
              seed: item.encSeed ? (Crypto.decrypt(item.encSeed, PasswordService.getPassword()) as string) : "",
              userWallet: uw!,
              provider_data: uw.data.provider_data,
            });
            wallets.value.push(w);
            const suw = getUser().user_wallets.find((uw1) => uw1.address === uw.data.linked_smart_address);
            wallets.value.push({
              ...w,
              is_smart: true,
              smart_address: uw.data.linked_smart_address,
              smart_data: {
                provider_data: suw?.data?.provider_data,
              },
              name: suw.name,
            });
          }
        }
      }
      importDone.value = true;
    }

    function saveTmpSecret(params: { pvk: string; address: string; seed?: string; name?: string }) {
      tmpSecret.value = params;
    }

    function checkExistImportWallet() {
      const listSaved = localStorage.getItem("import_wallets") || "[]";
      if (listSaved) {
        const list = JSON.parse(listSaved);
        if (list && list.length > 0) return true;
      }
      return false;
    }

    async function importWallet({ pvk, seed, address, name }: { pvk: string; address: string; seed?: string; name?: string }) {
      if (!PasswordService.getPassword()) return saveTmpSecret({ pvk, address, seed, name });
      const listSaved = localStorage.getItem("import_wallets");
      const list = JSON.parse(listSaved || "[]");
      const res = await postImportWallet(address, name || `EVM ${list.length + 1}`);
      const ownerItem = res?.find((item) => item.address === address);
      if (!ownerItem) throw new Error("Wallet address is import failed");
      getUserInfo().then((u) => setUser(u));

      const encPvk = Crypto.encrypt(pvk, PasswordService.getPassword());
      list.push({ encPvk: encPvk, encSeed: Crypto.encrypt(seed || "", PasswordService.getPassword()), address, name: ownerItem.name });
      localStorage.setItem("import_wallets", JSON.stringify(list));

      const w = createImportWallet({ pvk: pvk as Hex, seed, userWallet: ownerItem, provider_data: ownerItem.data.provider_data });
      const findSmart = res?.find((item) => item.data.is_smart_address);
      wallets.value.push(w as any);

      wallets.value.push({
        ...w,
        smart_address: ownerItem.data.linked_smart_address,
        is_smart: true,
        smart_data: {
          provider_data: findSmart?.data.provider_data,
        },
        name: findSmart?.name || "",
      });
      setCurrentAddress(address);
    }

    function deleteWallet(address: string) {
      const ownerWallet = getUser().user_wallets.find((uw) => uw.address === address);
      const eoa_address = ownerWallet?.data?.linked_eoa_address || address;
      const smart_address = ownerWallet?.data?.linked_smart_address || address;
      const listSaved = localStorage.getItem("import_wallets");
      const list = JSON.parse(listSaved || "[]");
      const newList = list.filter((item: any) => item.address !== eoa_address);
      localStorage.setItem("import_wallets", JSON.stringify(newList));
      wallets.value = wallets.value.filter((wallet) => wallet.address !== eoa_address);
      wallets.value = wallets.value.filter((wallet) => wallet.address !== smart_address);
    }

    function setCurrentAddress(smart_address: string) {
      currentSelectAddress.value = smart_address;
    }

    function findWalletWithAddress(smart_address: string) {
      if (!smart_address) return null;
      return wallets.value.find((wallet) => wallet.smart_address === smart_address);
    }

    function findWalletByType(blockchain: BLOCKCHAIN_TYPE) {
      return wallets.value.find((wallet) => wallet.blockchain === blockchain);
    }

    function getCurrentWallet() {
      if (!currentSelectAddress.value) {
        return wallets.value[0];
      }
      return findWalletWithAddress(currentSelectAddress.value) || wallets.value.find((wallet) => !!wallet.address) || wallets.value[0];
    }

    function changePassword(newPwd: string) {
      // TODO
      const smart_wallets = getUser().smart_wallets;

      if (!isEmpty(smart_wallets)) {
        const currentPwd = PasswordService.getPassword();
        smart_wallets.forEach((w) => {
          const enc = w.encrypted_credential;
          const rawValue = Crypto.decrypt(enc, currentPwd);
          const newEnc = Crypto.encrypt(rawValue, newPwd);
          w.encrypted_credential = newEnc;
        });
      }
      return smart_wallets;
    }

    return {
      availableWallets: computed(() => wallets.value.filter(w => !!w.smart_address)),
      wallets: computed(() => wallets.value),
      existMPCWallet: computed(() => !!getUser().smart_wallets.find((w) => w.blockchain)),
      backup_info: computed(() => getUser().mpc_data?.backup_info || null),
      findWalletWithAddress,
      init,
      getCurrentWallet,
      findWalletByType,
      changePassword,
      setCurrentAddress,
      initDone,
      initImportWallet,
      importWallet,
      deleteWallet,
      saveTmpSecret,
      tmpSecret,
      checkExistImportWallet,
    };
  })();
